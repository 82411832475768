import React from 'react';
import loadable from '@loadable/component';

const AnswerSelectV2 = loadable(() => import('./AnswerSelect2'));
const AnswerSelectV1 = loadable(() => import('./AnswerSelect1'));

const AnswerSelect = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <AnswerSelectV2 {...props} />;
    default:
    case 'version1':
      return <AnswerSelectV1 {...props} />;
  }
};

export default AnswerSelect;
